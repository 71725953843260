$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Dropdown
 -  (<div class="dropdown" />)
 */


.dropdown {
	position: relative;
	min-width: 150px;
	border-radius: $border-radius;

	&:focus {
		outline: none;
		box-shadow: $box-shadow-dropdown;
	}

	&::after {
		@include icon('caret-down');
		position: absolute;
		inset-inline-end: $spacer * .75;
		inset-block-start: 50%;
		transform: translateY(-50%);
		transition: transform ( $transition-speed * 2 ) $transition-easing;
		pointer-events: none;
	}

	// Create each dropdown kind and outline variant from colors list
	@each $name, $color in $dropdown-colors {
		&.dropdown--#{$name} {
			&::after {
				color: set-color-by-bg($color);
			}

			.dropdown__list,
			.dropdown__selected {
				background-color: $color;
				border: none;
				color: set-color-by-bg($color);
			}

			&:not(.open) .dropdown__selected {
				&:hover {
					// Switch lighter colors for dark variant
					@if (lightness($color) > 30) {
						background-color: darken($color, 10%);
					} @else {
						background-color: lighten($color, 10%);
					}
				}
			}

			&.open .dropdown__list,
			&.open .dropdown__selected {
				background-color: darken($color, 10%);
			}

			.dropdown__item {
				background-color: $color;

				&:hover,
				&:focus {
					// Switch lighter colors for dark variant
					@if (lightness($color) > 30) {
						background-color: darken($color, 10%);
						border-color: darken($color, 10%);
					} @else {
						background-color: lighten($color, 10%);
						border-color: lighten($color, 10%);
					}
				}
			}
		}

		&.dropdown--outline-#{$name} {
			&::after {
				@if $name == 'secondary' {
					color: $theme-gray-600;
				} @else {
					color: $color;
				}
			}

			@if $name == 'secondary' {
				&:hover::after {
					color: $color;
				}
			}

			.dropdown__selected {
				@if $name == 'secondary' {
					color: $theme-gray-600;
					border: $input-border-width solid $theme-gray-600;
				} @else {
					color: $color;
					border: $input-border-width solid $color;
				}
				background-color: transparent;

				&:hover,
				&:focus {
					background-color: $input-focused-bg;

					@if $name == 'secondary' {
						color: $secondary;
						border: $input-border-width solid $secondary;
					}
				}
			}

			&.open .dropdown__selected {
				background-color: $input-focused-bg;
			}

			.dropdown__list {
				border-top: none;
				background-color: $theme-white;
			}
		}
	}

	&.dropdown--blank,
	&.dropdown--inline {
		min-width: auto;

		&.open {
			.dropdown__selected {
				border: $input-border-width solid $input-focus-border-color;
				background-color: $input-focused-bg;
			}
		}

		.dropdown__selected {
			background-color: transparent;
			border: $input-border-width solid transparent;

			&:hover,
			&:focus {
				border: $input-border-width solid $input-focus-border-color;
				background-color: $input-focused-bg;
			}

			[class^='icon'],
			[class*='icon'] {
				font-size: 2rem;
			}
		}
	}

	&.dropdown--basic {
		border: $input-border-width solid $input-focus-border-color;
	}

	&.dropdown--sm {
		&::after {
			inset-inline-end: ( $spacer / 2 );
		}

		.dropdown__selected {
			height: $input-height-sm;
			padding: logical $input-padding-y-sm $input-padding-x-sm $input-padding-y-sm ( $input-padding-x-sm + $spacer );
		}

		.dropdown__selected,
		.dropdown__item {
			padding: logical $input-padding-y-sm $input-padding-x-sm;
		}
	}

	&.dropdown--h1 {
		margin-inline: -( $grid-gutter-width * .25 );

		// Media queries
		@include media-breakpoint-up(md) {
			display: inline-flex;
		}
		@include media-breakpoint-up(lg) {
			margin-inline: -$input-padding-x 0;
		}

		.dropdown__selected {
			padding-block: 0;
			font-size: $h1-font-size-mobile;
			font-weight: $headings-font-weight;
			text-transform: none;

			// Media queries
			@include media-breakpoint-down(md) {
				display: block;
				padding-inline-start: ( $grid-gutter-width * .25 );
				@include text-truncate;
			}
			@include media-breakpoint-up(sm) {
				font-size: $h1-font-size;
			}

			&:hover,
			&:focus {
				background-color: $theme-white;
				box-shadow: $box-shadow-dropdown;
			}
		}

		&.open {
			.dropdown__selected {
				background-color: $theme-white;
				box-shadow: $box-shadow-dropdown;
			}
		}
	}

	&.dropdown--inline {
		display: inline-flex;

		.dropdown__selected {
			padding-block: 0;
			padding-inline: ( $spacer * .5 ) $spacer;
			min-width: auto;
		}

		&::after {
			inset-inline-end: ( $spacer * .25 );
		}

		.dropdown__item {
			padding-inline: ( $spacer * .5 );
		}
	}

	&.open {
		box-shadow: $box-shadow-dropdown;

		&::after {
			transform: translateY(-50%) rotate(180deg);
			// z-index needs to be set for right stack with selected item and not interfere with other elements
			z-index: $zindex-popover + 1;
		}

		.dropdown__list {
			display: block;
		}

		.dropdown__selected {
			background-color: $white;
		}

		.dropdown__selected,
		& > .form-group--text-input {
			// z-index needs to be set for right stack with selected item and not interfere with other elements
			z-index: $zindex-popover + 1;
		}
	}

	.menu {
		inset-block-start: 100%;
		// Set border from selected item to center menu
		border: $btn-border-width solid transparent;
	}
}

.dropdown__selected {
	position: relative;
	display: flex;
	width: 100%;
	justify-content: flex-start;
	padding: logical $input-padding-y $input-padding-x $input-padding-y ( $input-padding-x + 2rem );
	text-align: start;
	overflow: hidden;

	[class^='icon'],
	[class*='icon'] {
		padding-inline-end: 8px;
		// Color has to be important to override default color of icon
		// stylelint-disable-next-line
		color: inherit !important;
	}

	&:focus:not(:disabled) {
		background-color: $white;
	}
}

.dropdown__list {
	position: absolute;
	// Temporary, width should be defined by JS from dropdown parent
	width: 100%;
	margin: logical ( -$input-border-width * 2 ) 0 0;
	padding: logical ( $input-border-width * 2 ) 0 0;
	display: none;
	list-style-type: none;
	border-block-start: none;
	background-color: $input-focused-bg;
	border-radius: 0 0 $border-radius $border-radius;
	box-shadow: $box-shadow-dropdown;
	color: $body-color;
	overflow: hidden;
	z-index: $zindex-popover;
}

.dropdown__item {
	padding: logical $input-padding-y $input-padding-x;
	text-align: start;
	font-weight: $input-font-weight;
	transition:
		box-shadow ( $transition-speed * 2 ) $transition-easing,
		background-color $transition-speed $transition-easing,
		border-color $transition-speed $transition-easing;

	&:hover:not(.dropdown__item--disabled),
	&:focus:not(.dropdown__item--disabled) {
		background-color: $theme-gray-100;
		cursor: pointer;
	}

	&--disabled {
		pointer-events: none;
		color: $text-muted;
	}
}
