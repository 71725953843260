$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Sticky Navigation
 -  (<h* class="h*" />)
 */

.sticky-navbar {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	width: 100%;
	height: $sticky-nav-height-xs;
	display: flex;
	align-items: center;
	transition: all $transition-easing ($transition-speed * 2);
	z-index: $zindex-fixed;
	// Media queries
	@include media-breakpoint-up(lg) {
		padding: logical ($spacer * 1.25) ($spacer * 2);
		height: $sticky-nav-height-default;
	}

	&:not(.is-transparent) {
		background-color: $body-bg;
	}

	&.is-sticky {
		position: fixed;
		height: $sticky-nav-height-xs;

		&:not(.is-transparent) {
			box-shadow: 0 2px 30px 0 rgba($black, 0.1);
		}

		// Media queries
		@include media-breakpoint-up(lg) {
			height: $sticky-nav-height;
		}
	}

	&.is-open {
		z-index: $zindex-fixed;

		.sticky-nav__list {
			box-shadow: inset 0 2px 30px 0 rgba($black, 0.1);
			transform: translateX(100%);
			opacity: 1;
			visibility: visible;
			overflow-x: hidden;
		}

		.sticky-nav__mobile-toggle span {
			&:first-child,
			&:last-child {
				opacity: 0;
				transform: translateX(100%);
			}

			&:nth-child(2) {
				transform: rotate(-45deg);

				&::after {
					transform: rotate(90deg);
				}
			}
		}
	}

	&__additional-content-container {
		display: flex;
		align-items: center;
		margin-inline-start: auto;
	}
}

.sticky-nav {
	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: absolute;
		inset-block-start: $sticky-nav-height-xs;
		inset-inline-start: 0;
		width: 100%;
		height: calc(100vh - #{$sticky-nav-height-xs});
		flex-direction: column;
		margin-inline-start: -100%;
		background-color: $white;
		padding-inline: $spacer;
		padding-block-start: ($spacer * 0.5);
		transform: translateX(0);
		opacity: 0;
		visibility: hidden;
		transition: all ($transition-speed * 2) $transition-easing;

		@include media-breakpoint-up(lg) {
			inset-block-start: $sticky-nav-height-default;
			height: calc(100vh - #{$sticky-nav-height-default});
			padding-inline-end: ($spacer * 3);
			padding-inline-start: ($spacer * 3);
		}
	}

	&__link {
		&,
		&:visited {
			text-transform: uppercase;
			font-weight: 600;
			color: $body-color;
			display: block;
			font-size: $font-size-base;
			padding-block: ($spacer * 0.75);
			border-block-end: 1px solid $theme-gray-300;

			@include media-breakpoint-up(lg) {
				font-size: 1.4rem;
			}
		}

		&:hover,
		&:focus,
		&.active {
			color: $primary;
			text-decoration: none;
		}
		white-space: nowrap;
	}

	&__mobile-toggle {
		width: $sticky-nav-toggle-width;
		height: $sticky-nav-toggle-height;
		display: inline-flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: flex-end;
		margin-inline-start: $spacer;
		overflow: hidden;

		span {
			display: block;
			height: 4px;
			border-radius: 4px;
			background-color: $theme-gray-800;
			opacity: 1;
			transition: all $transition-speed $transition-easing;

			&:first-child,
			&:last-child {
				width: 80%;
			}

			&:nth-child(2) {
				position: relative;
				width: 100%;

				&::after {
					content: '';
					position: absolute;
					display: block;
					width: 100%;
					height: 100%;
					border-radius: 4px;
					background-color: $theme-gray-800;
					transition: all $transition-speed $transition-easing;
				}
			}
		}
	}

	.logo-img {
		height: $sticky-nav-logo-height-mobile;

		// Media queries
		@include media-breakpoint-up(lg) {
			height: auto;
			max-height: $sticky-nav-logo-height;
		}
	}
}

.sticky-fade-in {
	opacity: 1;
	transition: opacity $transition-speed $transition-easing;
}

.sticky-fade-out {
	opacity: 0;
	visibility: hidden;
	transition:
		opacity $transition-speed $transition-easing,
		visibility 0s linear $transition-speed;
}

.sticky-navbar.is-sticky {
	.sticky-nav__list {
		@include media-breakpoint-up(lg) {
			inset-block-start: $sticky-nav-height;
			height: calc(100vh - #{$sticky-nav-height});
		}
	}
}

.sticky-navbar__additional-menu-item {
	display: none;

	@include media-breakpoint-up(md) {
		display: inline-flex;
	}
}

.sticky-navbar.is-open {
	.sticky-nav__additional-content {
		display: block;

		@include media-breakpoint-up(md) {
			display: none;
		}
	}
}

[dir='rtl'] {
	.sticky-navbar.is-open .sticky-nav__list {
			transform: translateX(-100%);
	}
}
