$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  DataList
 */


.data-list {
	width: 100%;
	margin-block-end: 0;

	&__term,
	&__desc {
		margin-block-end: ( $spacer * .25 );
		margin-inline-end: ( $spacer * .5 );
	}

	&__term {
		overflow: hidden;
		text-overflow: ellipsis;

		// Media queries
		@include media-breakpoint-down(xs) {
			width: 100%;
			margin-block-end: 0;
		}
		@include media-breakpoint-up(sm) {
			width: 40%;
		}
	}

	&__desc {
		display: flex;
		align-items: baseline;

		// Media queries
		@include media-breakpoint-down(xs) {
			margin-block-end: ( $spacer * .25 );
		}
		@include media-breakpoint-up(sm) {
			width: 60%;
		}
	}

	&--codes {
		.data-list__term,
		.data-list__desc {
			margin-block-end: ( $spacer * .5 );
		}

		.data-list__term {
			width: 20%;
		}

		.data-list__desc {
			width: 80%;
		}
	}

	&--info {
		.data-list__term {
			font-weight: 400;

			// Media queries
			@include media-breakpoint-up(sm) {
				width: 60%;
			}
		}

		.data-list__desc {
			font-weight: 600;

			// Media queries
			@include media-breakpoint-up(sm) {
				width: 40%;
			}
		}
	}
}