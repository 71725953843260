$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Form label
 -  (<label class="control-label" />)
 */


.control-label {
	position: absolute;
	inset: logical 0 0 auto auto;
	// padding-left: unset;
	// padding-right: unset;
	padding-inline-start: calc( #{$input-padding-x} + #{$input-border-width} );
	padding-inline-end: 0;
	font-size: $control-label-font-size;
	font-weight: 600;
	// letter-spacing: .5px;

	&--disabled,
	&--readonly {
		color: $control-label-disabled-color;
	}
}
