$env-myci-instance: lva;
@import '../../scss/core';

.panel__grid-item {
	position: relative;
	padding-inline: ($spacer * .5);
	flex-grow: 1;
	flex-basis: 0;
	max-width: 100%;

	// Media queries
	@include media-breakpoint-up(sm) {
		padding-inline: ($spacer * .5);

		& + & {
			&::before {
				content: '';
				position: absolute;
				height: 50px;
				width: 1px;
				inset-inline-start: 0;
				inset-block-start: 0;
				background-color: $theme-gray-300;
			}
		}
	}
	@include media-breakpoint-up(md) {
		& + & {
			&::before {
				inset-block-start: ($spacer * .5);
			}
		}
	}
	@include media-breakpoint-up(xl) {
		padding-inline: $spacer;
	}

	&--no-border {
		&::before {
			display: none;
		}
	}
}

.panel__grid {
	&--no-border {
		.panel__grid-item::before {
			content: none;
		}
	}

	&--scroll {
		.panel__grid-item {
			flex-shrink: 0;
		}
	}

	&--even {
		.panel__grid-item {
			// Media queries
			@include media-breakpoint-down(xs) {
				flex-basis: auto;
				width: 50%;
			}
			@include media-breakpoint-up(md) {
				flex: 1 1 auto;
			}
		}
	}

	&--odd {
		.panel__grid-item {
			// Media queries
			@include media-breakpoint-down(sm) {
				flex: 0 1 auto;
				width: 50%;
				margin-block-end: ( $spacer * .5 );

				& + .panel__grid-item::before {
					display: none;
				}
			}
		}
	}
}