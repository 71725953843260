$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Form control
 -  (<div class="collapsible" />)
 */


.collapsible {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: $collapsible-bg;
	border-radius: $collapsible-border-radius;
	box-shadow: $collapsible-box-shadow;
	// FIXME - react transitions
	// transition: all ( $transition-speed * 2 ) $transition-easing;

	// Media queries
	@include media-breakpoint-up(sm) {
		// Max-height is overwritten in JS, this is just a fallback
		// max-height: 800px;
	}

	&--hide {
		.collapsible__content {
			flex: 0 1 0;
			overflow: hidden;
			opacity: 0;
			transition: all $transition-speed $transition-easing;
		}

		.collapsible__status {
			flex: 1 0 auto;
			z-index: $zindex-filterform;
			opacity: 1;
			transition: all $transition-speed $transition-easing;
		}
	}

	&__hide-btn {
		position: absolute;
		inset-block-start: 18px;
		inset-inline-end: 50%;
		padding: logical 0;
		font-weight: 600;
		// Needs to be above other filterform elements
		z-index: $zindex-filterform + 1;
		transform: translateX(50%);

		&:focus {
			box-shadow: none;
		}

		// Media queries
		@include media-breakpoint-up(md) {
			inset-inline-end: $spacer;
			transform: none;
		}

		[class^='icon'],
		[class*='icon'] {
			padding-inline-start: 8px;
			padding-inline-end: 0;
			font-size: 1.5rem;
		}
	}

	&__content {
		flex: 1 0 auto;
		overflow: visible;
		transition: opacity ( $transition-speed * 2 ) $transition-easing;
		opacity: 1;

		&-inner {
			padding: logical $collapsible-max-height $collapsible-padding-x-mobile $collapsible-padding-y-mobile;

			// Media queries
			@include media-breakpoint-up(md) {
				padding: logical $collapsible-max-height $collapsible-padding-x ( $collapsible-padding-y - 1rem );
			}
		}
	}

	&__status {
		opacity: 0;
		flex: 0 1 0;
		overflow: hidden;
		transition: opacity ( $transition-speed * 2 ) $transition-easing;

		&-inner {
			// position: absolute;
			inset-block-start: 0;
			width: 100%;
			padding: logical $collapsible-max-height $collapsible-padding-x-mobile $collapsible-padding-y-mobile $collapsible-padding-x-mobile;
			// padding-inline: $collapsible-padding-x-mobile;
			display: flex;
			flex-direction: column;
			transform-origin: top;
			z-index: -1;

			// Media queries
			@include media-breakpoint-up(md) {
				padding: logical ( $spacer * .75 ) $collapsible-padding-x ( $spacer * .75 ) ( $collapsible-padding-x + 15rem );
				min-height: $collapsible-max-height;
				flex-direction: row;
			}
		}
	}

	&__title {
		width: 100%;
		flex-shrink: 0;

		// Media queries
		@include media-breakpoint-up(sm) {
			width: auto;
		}
	}

	&__divider {
		margin-inline: -$collapsible-padding-x-mobile;

		// Media queries
		@include media-breakpoint-up(sm) {
			margin-inline: -$collapsible-padding-x;
		}
	}

	&__cell {
		.form-group {
			width: 100%;
		}
	}
}

[dir='rtl'] {
	.collapsible__hide-btn {
		transform: translateX(-50%);

		// Media queries
		@include media-breakpoint-up(md) {
			transform: none;
		}
	}
}