$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Editable text
 -  (<input class="is-editable" />)
 */

.editable-text {
	position: relative;
	cursor: pointer;

	&:not(.editable-text--is-editing):hover,
	&:not(.editable-text--is-editing):focus {
		&::before {
			content: '';
			position: absolute;
			// inset-block-start: $input-border-width;
			inset-inline-start: -( $spacer * .5 );
			width: calc( 100% + #{$spacer} );
			height: 100%;
			box-shadow: 0 0 0 $input-border-width $input-hover-border-color;
			border-radius: $input-border-radius;
			background-color: $input-focused-bg;
			z-index: -1;
		}
	}

	&__check {
		position: absolute;
		inset-block-start: calc( 100% + #{$input-border-width} );
		inset-inline-end: ($spacer * .5);
		background-color: $theme-white;
		border-radius: 0 0 $border-radius $border-radius;
		/* border-end-end-radius: $border-radius;
		border-start-end-radius: $border-radius; */
		box-shadow: $box-shadow-basic;
		z-index: $zindex-popover;

		.btn {
			padding: logical ( $spacer * .5 );

			[class^='icon'],
			[class*='icon'] {
				padding-inline-end: 0;
			}
		}
	}

	&__text {
		margin-block-end: 0;
	}

	.form-control {
		border: none;
		padding-inline: ( $spacer * .5 );
		margin-inline-start: -( $spacer * .5 );
		box-shadow: 0 0 0 $input-border-width $input-hover-border-color;
		color: $body-color;
	}

	&--type-h1 {
		position: relative;

		.form-control {
			font-size: $h1-font-size-mobile;
			font-weight: $headings-font-weight;
			line-height: $h1-line-height;
			height: calc(( #{$h1-font-size-mobile} * #{$h1-line-height} ));

			// Media queries
			@include media-breakpoint-up(sm) {
				font-size: $h1-font-size;
				height: calc(( #{$h1-font-size} * #{$h1-line-height} ));
			}
		}

		.editable-text__icon {
			margin-inline-start: $spacer;

			// Media queries
			@include media-breakpoint-down(sm) {
				// Overwrite utility class
				// stylelint-disable-next-line
				font-size: 2.4rem !important;
			}
		}

		.editable-text__check {
			.btn {
				[class^='icon'],
				[class*='icon'] {
					// Overwrite utility class
					// stylelint-disable-next-line
					font-size: 2rem !important;
				}
			}
		}
	}

	&--type-p {
		.form-control {
			font-size: $font-size-base;
			line-height: $line-height-base;
			height: calc(( #{$font-size-base} * #{$line-height-base} ));
		}

		.editable-text__icon {
			margin-inline-start: ( $spacer * .5 );
		}
	}
}

.editable-heading {
	margin-block-end: 0;
}
