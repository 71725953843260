$env-myci-instance: lva;
@import '../../scss/core';
@include font-face(quote(#{$icon-filename}), '~@myci/assets/fonts/icons/#{$icon-filename}', normal);

.icon {
	@include icon;
}

@each $icon, $content in $icons {
	.icon--type-#{$icon}::before {
		content: $content;
		display: inline-block;
		// text-decoration: none;
	}
}

$icon-sizes: (
	8,
	10,
	12,
	14,
	16,
	18,
	20,
	22,
	24,
	26,
	30,
	36,
	50
);

@each $size in $icon-sizes {
	.icon--size-#{$size} {
		// stylelint-disable-next-line
		font-size: #{$size / 10}rem !important;
	}
}

// stylelint-disable
:export {
	icons: map-keys($icons);
	icon-sizes: $icon-sizes;
}
// stylelint-enable
