$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Info Bar
 */


.info-tab {
	position: fixed;
	inset-block-start: 100px;
	inset-inline-end: 0;
	border-radius: $border-radius 0 0 $border-radius;
	box-shadow: $info-tab-box-shadow;
	background-color: $info-tab-bg-color;
	padding: ( $spacer * .75 );
	z-index: $zindex-tooltip;
	cursor: pointer;

	&__hide {
		display: block;
		flex: 0;
		width: 0;
		overflow: hidden;
	}

	&:hover,
	&:focus {
		.info-tab__hide {
			flex: 1;
			width: auto;
		}
	}
}