$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Select
 -  (<select class="form-control" />)
 */


.form-group--select {
	position: relative;

	&.is-focused {
		.control-label {
			pointer-events: auto;
		}
	}

	&.has-error {
		.form-control {
			border-color: $form-feedback-invalid-color;
		}
	}

	.form-control {
		display: inline-block;
		width: 100%;
		min-width: auto;
		height: $custom-select-height;
		padding: logical $custom-select-padding-y $custom-select-padding-x $custom-select-padding-y ( $custom-select-padding-x + $custom-select-indicator-padding );
		font-family: $custom-select-font-family;
		@include font-size($custom-select-font-size);
		font-weight: $custom-select-font-weight;
		line-height: $custom-select-line-height;
		color: $custom-select-color;
		vertical-align: middle;
		background: $custom-select-background;
		background-color: $custom-select-bg;
		background-size: $custom-select-bg-size;
		border: $custom-select-border-width solid $custom-select-border-color;
		border-radius: $custom-select-border-radius;
		appearance: none;
		text-align: start;
		text-transform: none;
		@include text-truncate;

		&:hover:not(:disabled) {
			cursor: pointer;
			border-color: $input-hover-border-color;
		}

		&:focus {
			border-color: $input-focus-border-color;
			background-color: $input-focused-bg;
			outline: 0;
			box-shadow: $custom-select-focus-box-shadow;

			&::-ms-value {
				color: $input-color;
				background-color: $input-bg;
			}
		}

		&[multiple],
		&[size]:not([size='1']) {
			height: auto;
			padding-inline-end: $custom-select-padding-x;
			background-image: none;
		}

		&:disabled {
			color: $custom-select-disabled-color;
			background-color: $custom-select-disabled-bg;
			background-image: $custom-select-disabled-indicator;
		}

		// Hides the default caret in IE11
		&::-ms-expand {
			display: none;
		}

		&-sm {
			height: $custom-select-height-sm;
			padding: logical $custom-select-padding-y-sm $custom-select-padding-x-sm $custom-select-padding-y-sm ( $custom-select-indicator-padding + $custom-select-indicator-offset-sm );
			background-image: $custom-select-indicator no-repeat right $custom-select-indicator-offset-sm center / $custom-select-bg-size;
			font-size: $custom-select-font-size-sm;
		}

		&-lg {
			height: $custom-select-height-lg;
			padding: logical $custom-select-padding-y-lg $custom-select-padding-x-lg $custom-select-padding-y-lg 0;
			font-size: $custom-select-font-size-lg;
		}

		&--active {
			position: relative;
			// Needs to be above popover
			z-index: $zindex-popover + 1;
		}
	}

	.menu {
		inset-inline-start: 0;
	}
}

[dir='rtl'] {
	.form-group--select {
		.form-control {
			background: $custom-select-indicator no-repeat left $custom-select-indicator-padding center / $custom-select-bg-size;
		}

		.form-control-sm {
			background: $custom-select-indicator no-repeat left $custom-select-indicator-offset-sm center / $custom-select-bg-size;
		}

		.form-control,
		.form-control-sm {
			background-color: $custom-select-bg;

			&:disabled {
				color: $custom-select-disabled-color;
				background-color: $custom-select-disabled-bg;
				background-image: $custom-select-disabled-indicator;
			}
		}
	}
}