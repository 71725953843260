$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Skeletor
 -  (<Skeletor />)
 */


.skeletor {
	display: block;
	background-color: $skeletor-bg-color;
}

.skeletor--variant-text {
	margin-block: 0;
	height: auto;
	transform-origin: 0 60%;
	transform: scale(1, .60);

	&:empty:before {
		content: '\\00a0';
	}
}

.skeletor--variant-rectangle {
	border-radius: $border-radius;
}

.skeletor--variant-circle {
	border-radius: 50%;
}

.skeletor--animate {
	animation: change-opacity 1.5s ease-in-out infinite;
}

@keyframes change-opacity {
	0% {
		opacity: 1;
	}
	50% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
