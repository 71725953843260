$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Score Graph
 */


.score-graph {
	.recharts-wrapper {
		// HACK: recharts lib cannot count tooltip position propertly on RTL.
		// see https://github.com/recharts/recharts/issues/263
		direction: ltr;
	}
	.yAxis {
		.recharts-text {
			fill: $score-graph-y-text-color;
		}
	}

	.xAxis {
		.recharts-text {
			fill: $score-graph-x-text-color;
		}

		.recharts-cartesian-axis-line {
			stroke: $score-graph-x-line-color;
		}
	}

	.recharts-tooltip-cursor {
		stroke: $score-graph-cursor-color;
		opacity: .4;
	}

	.score-graph__tooltip {
		position: relative;
		padding: logical $spacer;
		background-color: $score-graph-tooltip-bg;
		box-shadow: $box-shadow-dropdown;
		border-radius: $border-radius;

		// Media queries
		@include media-breakpoint-down(md) {
			padding: ( $spacer / 2 );
		}

		&-value {
			font-size: 2rem;

			// Media queries
			@include media-breakpoint-down(md) {
				font-size: $font-size-sm;
			}
		}

		&-item {
			text-align: center;

			// Media queries
			@include media-breakpoint-down(md) {
				font-size: $font-size-sm;
			}

			& + .score-graph__tooltip-item {
				margin-inline-start: $spacer;
			}
		}

		&-arrow {
			position: absolute;
			width: 30px;
			height: 10px;
			margin-inline-start: -15px;

			&::before {
				content: '';
				margin: logical auto;
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
			}
		}
	}

	.recharts-tooltip-wrapper {
		//Medua queries
		@include media-breakpoint-down(md) {
			// need to be here to override inline styles from JS scripts
			// stylelint-disable-next-line
			margin-left: -2rem !important;
		}
	}

	.recharts-tooltip-wrapper-bottom {
		margin-block-start: 30px;

		.score-graph__tooltip-arrow {
			inset-block-end: 100%;

			&::before {
				border-width: 0 15px 10px;
				border-color: transparent transparent $score-graph-tooltip-bg;
			}
		}
	}

	.recharts-tooltip-wrapper-top {
		margin-block-start: -30px;

		.score-graph__tooltip-arrow {
			inset-block-start: 100%;

			&::before {
				border-width: 10px 10px 0;
				border-color: $score-graph-tooltip-bg transparent transparent;
			}
		}
	}

	.recharts-tooltip-wrapper-right {
		margin-inline-start: -30px;

		.score-graph__tooltip-arrow {
			inset-inline-start: 30px;

			&:dir(rtl) {
				right: auto;
				left: 30px;
				margin-left: -15px;
			}
		}
	}

	.recharts-tooltip-wrapper-left {
		margin-inline-start: 30px;

		.score-graph__tooltip-arrow {
			inset-inline-end: 30px;
			margin-inline-end: -15px;

			&:dir(rtl) {
				left: auto;
				right: 30px;
			}
		}
	}

	.recharts-line-curve {
		stroke: $score-graph-line-color;
		stroke-width: $score-graph-line-width;
	}

	.recharts-line-dot {
		stroke: $score-graph-dot-color;
		stroke-width: $score-graph-dot-stroke-width;
		r: $score-graph-dot-radius;
		fill: $score-graph-dot-fill;
	}

	.recharts-active-dot .recharts-dot {
		stroke: $score-graph-active-dot-color;
		stroke-width: $score-graph-active-dot-stroke-width;
		r: $score-graph-active-dot-radius;
		fill: $score-graph-active-dot-fill;
	}
}