$env-myci-instance: lva;
@import '../../scss/core';

// Change in mixin to support RTL
@mixin make-col-offset($size, $columns: $grid-columns) {
	$num: $size / $columns;
	margin-inline-start: if($num == 0, 0, percentage($num));
}

@import '~bootstrap/scss/grid';
