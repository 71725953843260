$env-myci-instance: lva;
@import '../../scss/core';
@import '~bootstrap/scss/forms';

/*
 -  Form group
 -  (<div class="form-group" />)
 */


.form-group {
	position: relative;
	min-width: 100px;
	text-align: start;
	@include control-label-offset;

	&.floating-label {
		/* stylelint-disable selector-class-pattern */
		.form-control,
		.DateInput_input {
			@include custom-placeholder(transparent);
		}
		/* stylelint-enable */

		.control-label {
			inset-block-start: calc((#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border * 2}) / 2 + #{($control-label-floating-font-size * $line-height-base + $floating-label-offset) / 2 });
			font-size: $control-label-font-size;
			// transform-origin: left;
			// set initial transform for performance
			transform: translateZ(0);
			transition: all ( $transition-speed * 1.5 ) $transition-easing;
			text-transform: uppercase;
		}

		&.is-focused,
		&.is-filled {
			.control-label {
				// NOTE: fixes temporarily selects
				// inset-block-start: 0;
				transform: translateZ(0);
				font-size: $control-label-floating-font-size;
			}
		}

		&.is-filled {
			/* stylelint-disable selector-class-pattern */
			.form-control:not([disabled]):not([readonly]):not(.is-editable),
			.DateInput_input:not([disabled]):not([readonly]):not(.is-editable) {
				background-color: $input-filled-bg;
				border-color: $input-filled-border-color;
			}
			/* stylelint-enable */
		}

		&.is-focused {
			/* stylelint-disable selector-class-pattern */
			.form-control:not([disabled]):not([readonly]),
			.DateInput_input:not([disabled]):not([readonly]) {
				background-color: $input-focused-bg;
			}
			/* stylelint-enable */

			.is-editable:not([disabled]):not([readonly]) {
				background-color: $input-filled-bg;
				border: $input-border-width solid $input-filled-border-color;
				padding: logical $input-padding-x-sm $input-padding-y-sm;
			}
		}
	}

	&.has-suffix {
		.form-control {
			padding-inline-end: ( $input-padding-x + 3.4rem );
		}
	}

	&.has-error {
		.form-control {
			&:focus {
				box-shadow: $box-shadow-danger;
			}
		}
	}

	&.has-warning,
	&.has-error,
	&.has-success {
		.valid-feedback,
		.invalid-feedback,
		.form-text {
			display: block;
		}
	}

	&.is-required:not(.is-disabled),
	&.is-group-required:not(.is-disabled) {
		.required-sign {
			color: $danger;
		}
	}

	&.without-label {
		padding-block-start: 0;

		.form-control {
			& + .form-control__legend {
				inset-block-start: 10px;
			}

			&-sm + .form-control__legend {
				inset-block-start: 4px;
			}
		}

		.form-control__suffix {
			inset-block-start: calc((#{$input-padding-y * 2} + #{$input-height-border * 2} - #{$input-button-size / 2}) / 2);
		}

		.editable-heading + .form-control__suffix {
			inset-block-start: auto;
			inset-block-end: 12px;
		}
	}

	@each $name, $color in $theme-colors {
		& .form-control.outline-#{$name} {
			color: $color;
			border-color: $color;
			background-color: transparent;

			&:hover:not(:disabled),
			&:focus {
				border-color: darken($color, 5%);
				background-color: $theme-white;
			}

			& + .control-label {
				color: $color;
			}
		}
	}

	&--checkbox,
	&--checkbox-group,
	&--radio {
		min-width: auto;

		.valid-feedback,
		.invalid-feedback,
		.form-text {
			padding-inline-start: 0;
		}
	}

	&--toggle {
		padding-block-start: 0;
		min-width: auto;
	}

	&__radio-box:not(:last-child) {
		margin-right: 4rem;
	}

	&--select {
		@each $name, $color in $theme-colors {
			& .form-control.outline-#{$name} {
				background-image: str-replace( url("data:image/svg+xml,<svg viewBox='0 0 8 7' version='1.1' xmlns='http://www.w3.org/2000/svg'><path d='M7.57,0.005c0.022,0.005 0.046,0.008 0.068,0.014c0.179,0.052 0.322,0.208 0.355,0.396c0.016,0.092 0.005,0.188 -0.03,0.274c-0.009,0.022 -0.021,0.042 -0.031,0.063l-3.501,6.002c-0.013,0.019 -0.025,0.039 -0.039,0.057c-0.129,0.164 -0.365,0.233 -0.563,0.161c-0.088,-0.032 -0.165,-0.088 -0.223,-0.161c-0.014,-0.018 -0.026,-0.038 -0.039,-0.057l-3.501,-6.002c-0.01,-0.021 -0.022,-0.041 -0.031,-0.063c-0.079,-0.193 -0.022,-0.434 0.141,-0.572c0.072,-0.06 0.16,-0.099 0.252,-0.112c0.024,-0.003 0.047,-0.003 0.07,-0.005l7.002,0c0.023,0.002 0.046,0.003 0.07,0.005Z' style='fill:#{$color};'/></svg>"), '#', '%23' );
			}
		}

		.btn-default:not(.disabled),
		.btn-default:not(:disabled) {
			border-color: $input-border-color;
		}
	}

	&--attachments {
		&.has-error {
			.btn-outline-secondary:not(.disabled),
			.btn-outline-secondary:not(:disabled) {
				border-color: $input-invalid-color;
			}

			.attachments__list {
				color: $input-invalid-color;
			}
		}
	}

	&--textarea {
		.control-label {
			z-index: -1;
		}

		.collapsible & .control-label {
			z-index: 0;
		}

		&.without-label {
			padding-block-start: 0;

			.form-control {
				& + .form-control__legend {
					inset-block-start: auto;
				}
			}
		}
	}

	&__counter {
		position: absolute;
		inset-inline-end: 0;
		inset-block-start: calc(100% + 10px);
	}

	&.with-legend {
		.form-control {
			padding-inline-end: ( $input-padding-x + $spacer * 1.25 );

			&-sm {
				padding-inline-end: ( $input-padding-x-sm + $spacer * 1.25 );
			}
		}
	}

	&.inline {
		.invalid-feedback,
		.form-text {
			// Media queries
			@include media-breakpoint-up(xl) {
				position: absolute;
				inset-block-start: 0;
				inset-inline-start: calc(100% + #{$spacer});
				max-width: 240px;
				white-space: nowrap;
			}
		}
	}

	& + &,
	.input-wrapper + &,
	& + .input-wrapper {
		margin-top: $spacer;
	}
}

.input-wrapper + .input-wrapper {
	margin-top: $spacer;
}

.form-control__suffix {
	position: absolute;
	inset-block-start: calc((#{$input-padding-y * 2} + #{$input-height-border * 2} - #{$input-button-size / 2}) / 2 + #{$control-label-floating-font-size * $line-height-base + $floating-label-offset});
	inset-inline-end: ( $spacer * .75 );

	[class^='icon'],
	[class*='icon'] {
		vertical-align: middle;
	}

	.btn:hover:not(:disabled),
	.btn:focus:not(:disabled) {
		cursor: pointer;

		[class^='icon'],
		[class*='icon'] {
			// stylelint-disable-next-line
			color: $primary !important;
		}
	}
}

.form-group__button {
	padding-block-start: calc((#{$input-padding-y * 2} + #{$input-height-border * 2} - #{$input-button-size / 2}) / 2 + #{$control-label-floating-font-size * $line-height-base + $floating-label-offset});
}

.form-group__checkbox-box {
	margin-right: 13px;
}
