$env-myci-instance: lva;
@import '../../scss/core';

/*
 -  Toggle
 -  (<input type="checkbox" class="form-control" />)
 */


.form-group--toggle {
	.control-label {
		height: $toggle-height;
		width: $toggle-width;
		margin-block-end: 0;
		background-color: $theme-green;
		border-radius: ( $toggle-height * .5 );
		color: $toggle-color;
		font-weight: 700;
		font-size: $toggle-font-size;
		text-transform: uppercase;
		display: inline-flex;
		align-items: center;
		position: relative;
	}

	.form-control {
		position: absolute;
		height: auto;
		z-index: -1;
		opacity: 0;

		& ~ .control-label {
			background-color: $theme-red;
			justify-content: flex-end;
			padding-inline-end: ( $toggle-icon-size + 10px );
			padding-inline-start: 0;
			transition:
				all $transition-speed $transition-easing;
			cursor: pointer;
			user-select: none;
		}

		&:focus ~ .control-label {
			box-shadow: $input-focus-box-shadow;
		}

		& ~ .control-label::before {
			@include icon(status-rejected);
			position: absolute;
			inset-block-start: $toggle-icon-offset;
			inset-inline: auto $toggle-icon-offset;
			display: block;
			font-size: $toggle-icon-size;
			color: $toggle-icon-color;
			transition: all $transition-speed $transition-easing;
			transform: translateX(0);
		}

		&:checked ~ .control-label {
			padding-inline-start: ( $toggle-icon-size + 10px );
			padding-inline-end: 0;
			background-color: $toggle-true-bg;
			justify-content: flex-start;
		}

		&:checked ~ .control-label::before {
			@include icon-content(status-success);
			inset-block-start: $toggle-icon-offset;
			transform: translateX( -($toggle-width - $toggle-icon-size - $toggle-icon-offset * 3) );

			&:dir(rtl) {
				transform: translateX( $toggle-width - $toggle-icon-size - $toggle-icon-offset * 3 );
			}
		}

		&:disabled,
		&[disabled] {
			& ~ .control-label {
				background-color: $theme-gray-400;
				color: $white;
			}
		}
	}
}